import { ReactElement } from 'react';
import { AppProps } from 'next/app';

import { Providers } from '../components';

import '../styles/global.css';
import 'tailwindcss/tailwind.css';

function App({ Component, pageProps }: AppProps): ReactElement {
    return (
        <Providers>
            <Component {...pageProps} />
        </Providers>
    );
}

export default App;
