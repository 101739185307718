// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://868e0df126ac43668ad0db6a7d83a391@o72227.ingest.sentry.io/5807855',
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});
